.ecommerce_navbar{
    background-color: #016131
}
.ecommerce_navbar .navbar-brand{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 22px;
}
.commerce_menu_d_m li a,
.commerce_menu_d_m li button{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 16px;
}

ul.commerce_menu_d_m {
    padding-left: 50px;
    padding-top: 5px;
}
.commerce_menu_d_m .dropdown-menu{
    background-color: #016131
}

button.cart_flastone_ecommerce_shop {
    position: relative;
    border: none;
    background-color: #016131;
    padding: 0;
    margin-right: 10px;
}
button.cart_flastone_ecommerce_shop svg{
    width: 25px;
    height: 25px;
}
button.cart_flastone_ecommerce_shop svg path{
    fill: #fff;
}
button.cart_flastone_ecommerce_shop .badge_cart {
    background-color: #ff0000;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    text-align: center;
    position: absolute;
    top: -5px;
    right: -10px;
}
button.search_flastone_ecommerce {
    background-color: #016131;
    border: none;
}
button.search_flastone_ecommerce  svg{
    width: 25px;
    height: 25px;
}
button.search_flastone_ecommerce  svg path{
    fill: #fff;
}
.elements_shop_mobile{
    display: none;
}
.section_slide_home .image_carousel{
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.section_slide_home .container_cover_slide{
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}
.section_slide_home{
    position: relative;
}
.section_slide_home .slick-arrow{
  position: absolute;
  z-index: 1;
} 
.section_slide_home .slick-prev{
    left: 20px;
}
.section_slide_home .slick-next{
    right: 20px;
}
.container_cover_slide {
    padding-top: 20vh;
}
.container_cover_slide h2{
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 28px;
}
.container_cover_slide p{
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}
.container_cover_slide button{
    background-color: #016131;
    border: none;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 15px;
    border-radius: 5px;
}
.container_cover_slide button:hover{
    background-color: #262626;
}
.footer_site{
    background-color: #262626;
    padding: 60px 0 30px 0;
}
.footer_site .inide_aside_section h2{
    color: #65bc51;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 18px;
    font-size: 16px;
}
.footer_site .inide_aside_section ul li{
    list-style: none;
}
.footer_site .inide_aside_section ul{
    padding-left: 0;
}
.footer_site .inide_aside_section ul a{
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    transition: all 0.3s ease;
    text-decoration: none;
    margin-bottom: 10px;
}
.footer_site .inide_aside_section .logo_footer a{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #fff;
    font-size: 22px;
}
.footer_site .inide_aside_section p.descPcompany{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    margin-top: 15px;
}
.line_separator_smecher p,
.footer_site .inide_aside_section .item_sec_col .data_sc a,
.footer_site .inide_aside_section .item_sec_col .data_sc p{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    margin-top: 15px;
    text-decoration: none;
}
.footer_site .inide_aside_section .item_sec_col{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.footer_site .inide_aside_section .item_sec_col .data_sc{
    padding-left: 15px;
}
.line_separator_smecher {
    border-top: 1px solid #ffffff85;
    padding-top: 15px;
}
.sal_sol_images {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 20px;
    flex-wrap: wrap;
}
.sal_sol_col{
    padding: 5px;
}
.sal_sol_col img{
    width: 250px;
    border-radius: 15px;
}
.card_produs_el_component .card_prod_image{
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;
}
.card_produs_el_component .card_prod_image a {
    width: 100%;
    display: inline-block;
    padding: 0 15px;
    background-color: #fff;
}
.card_produs_el_component .card_prod_image .cover_img_conmpnent{
    width: 100%;
    height: 325px;
    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
}
.card_produs_el_component .card_prod_image .cover_img_conmpnent:hover{
    cursor: pointer;
}
.card_produs_el_component .produse_info_details{
    padding: 0 15px;
}
.card_produs_el_component .produse_info_details .produs_title_c{
    border-bottom: 1px solid #e2e2e2;
    padding-top: 20px;
    padding-bottom: 15px;
}
.card_produs_el_component .produse_info_details .produs_title_c h4{
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Roboto';
}
.card_produs_el_component .produse_info_details .produs_title_c h4 span{
    display: block;
    display: -webkit-box;
    max-width: 100%;
    color: #393938;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    word-break: break-word;
    text-decoration: none;
    height: 50px;
}
.card_produs_el_component .produse_info_details .produs_title_c h4 span:hover{
    cursor: pointer;
}
.slider_carousel_slick{
    position: relative;
}
.slider_carousel_slick .carousel_prod_intern{
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 13px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #fff;
}
.slider_carousel_slick .carousel_prod_extern{
    padding: 20px;
}
.slider_carousel_slick .slick-arrow{
    display: none!important;
}
.card_produs_el_component .price_details_prod{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.card_produs_el_component .price_details_prod p{
    font-family: "Roboto",sans-serif;
    color: #878787;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.7em;
    margin:0px;
}
.card_produs_el_component .price_details_prod p .body_price{
    font-size: 20px;
    color: #393939;
    font-weight: 700;
}
.card_produs_el_component .price_details_prod p .body_price sup{
    font-size: 13px;
}
.card_produs_el_component .price_details_prod a.nchor_single_prod{
    width: 55px;
    height: 55px;
    border: 2px solid #65bc51;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card_produs_el_component .price_details_prod a.nchor_single_prod:hover{
    background-color: #65bc51;
}
.card_produs_el_component .price_details_prod a.nchor_single_prod:hover svg{
    fill: #fff;
}
section.shops_carousels_home{
    margin-top: 60px;
    margin-bottom: 60px;
}
section.shops_carousels_home .shop_carousel_row.container{
    margin-bottom: 40px;
}
section.shops_carousels_home .title_categ_ecommerce_car{
    color: #393939;
    font-size: 30px;
    font-family: 'Roboto';
}
section.bradcrumbs_page {
    background-color: #f5f5f5;
    padding-top: 18px;
    padding-bottom: 18px;
}
section.bradcrumbs_page .brewd_crumb_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
section.bradcrumbs_page .brewd_crumb_container a{
    text-decoration: none;
    color: #016131;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
}
section.bradcrumbs_page .brewd_crumb_container span {
    color: #262626;
    font-weight: 600;
    margin-left: 3px;
    margin-right: 3px;
}
section.bradcrumbs_page .brewd_crumb_container p{
    margin-bottom: 0;
    color: #262626;
    font-weight: 400;
}
.card_inside_prod_row_col{
    padding-top: 14px;
    padding-bottom: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 13px;
    transition: all 0.3s ease;
    background: #fff;
    margin-bottom: 20px;
}
section.section_produse_categ {
    margin-top: 55px;
    margin-bottom: 45px;
}
.section_col_filter_desktop{
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 13px;
    border: 1px solid #e1e1e1;
}
.component_filter_prod{
    margin-top: 5px;
    margin-bottom: 5px;
}
.component_filter_prod h2{
    color: #878787;
    font-size: 17px;
    font-family: 'Montserrat';
    font-weight: 500;
}
.component_filter_prod .list_filter_categ{
    padding-left: 0;
}
.component_filter_prod .list_filter_categ li{
    list-style: none;
}
.component_filter_prod .list_filter_categ li a{
    text-decoration: none;
    color: #016131;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
}
.data_shop_up_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.data_shop_up_filter select.form-select {
    width: 270px;
}
.data_shop_up_filter h3{
    color: #878787;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
}
.sectin_filter_mobile{
    display: none;
}
.filter_mobile_ignite{
    border: none;
    background-color: #016131;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
}
section.section_contact_shop {
    margin-top: 50px;
    margin-bottom: 50px;
}
.shop_contact_form{
    background-color: #fff;
    padding: 38px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.11);
}
.shop_contact_form input, 
.shop_contact_form label,
.shop_contact_form textarea{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #262626;
}
.shop_contact_form label a {
    color: #262626;
}
.btn_shop_style {
    background-color: #016131;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
.btn_shop_style:hover{
    background-color: #016131;
    color: #fff;
}
.content_details_shop ul{
    padding-left: 0px;
}
.content_details_shop ul li.list_shop_details{
    list-style: none;
    margin-bottom: 20px;
}
.content_details_shop .icons_contact_shop {
    display: flex;
    align-items: center;
}
.content_details_shop .icon_sop {
    margin-right: 15px;
}
.content_details_shop .title_sec_sop span {
    color: #878787;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.content_details_shop .item_sop_details span,
.content_details_shop .item_sop_details a{
    color: #1A1A1B;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    margin-right: 10px;
}
.map_ecommerce_app{
    margin-bottom: 10px;
    padding-top: 10px;
}
.map_ecommerce_app iframe{
    width: 100%;
    height: 300px;
}
section.container_auth_section {
    margin-top: 50px;
    margin-bottom: 60px;
}
.container_auth_section .row_interior_form {
    padding: 10px 20px;
}
.container_auth_section .contact_form_auth {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
}
.container_auth_section .contact_form_auth h2{
    font-family: 'Montserrat';
    font-size: 18px;
    margin-bottom: 15px;
}
.container_auth_section .contact_form_auth label{
    font-family: 'Montserrat';
    font-size: 16px;
}
.container_auth_section .contact_form_auth  input{
    font-family: 'Montserrat';
    font-size: 16px;
}
.send_btn_auth_modal_forgot,
.container_auth_section .contact_form_auth .send_btn_auth{
    background-color: #016131;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
.for_pass_gott{
    font-family: 'Montserrat';
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 500;
}
.for_pass_gott:hover{
    cursor: pointer;
}
.title_modal_modificat {
    font-size: 17px;
    font-family: 'Montserrat';
}
.list_order_mini_cart ul{
    padding-left: 0;
}
.list_order_mini_cart ul li{
    list-style: none;
}
.list_order_mini_cart .card_minicar_product{
    padding: 5px 5px 15px 0px;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod{
    display: flex;
    width: 100%;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .image_mini_prod_container img{
    width: 80px;
    height: 80px;
    object-fit:contain;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .image_mini_prod_container{
    width: 90px;
    height: 80px;
    overflow: hidden;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .cart_mini_prod_data{
    width: calc(100% - 90px);
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .cart_mini_prod_data .input_increment_value_cart{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .cart_mini_prod_data h4{
    font-size: 17px;
    font-family: 'Montserrat';
    margin-bottom: 0;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod .cart_mini_prod_data .input_increment_value_cart{
    display: flex;
    margin-top: 15px;
    align-items: baseline;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .container_counter_quantity{
    display: flex;
    align-items: center;
}
.cantitate_order_checkout button,
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .container_counter_quantity button{
    border:none;
    background-color: #016131;
    color: #fff;
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.cantitate_order_checkout button svg,
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .container_counter_quantity button svg{
    width: 15px;
    height: 15px;
}
.cantitate_order_checkout p,
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .container_counter_quantity p{
    margin-bottom: 0;
    width: 35px;
    text-align: center;
    color: #016131;
    font-size: 17px;
    font-family: 'Roboto';
    font-weight: 700;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .price_to_cart{
    color: #1A1A1B;
    font-size: 16px;
    font-family: 'Montserrat';
}
.list_order_mini_cart ul li{
    border-top: 1px solid #016131;
    padding-top: 5px;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .cart_prod_header_top_l{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.list_order_mini_cart .card_minicar_product .mnicart_inside_prod  .cart_prod_header_top_l button{
    background-color: #ea1919;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 25px;
    width: 25px;
    border-radius: 4px;
}
.title_cart_icon{
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.title_cart_icon svg{
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
.price_total_minicart {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 8px 10px;
}
.price_total_minicart span{
    color: #1A1A1B;
    font-size: 17px;
    font-weight: 600;
}
.offcanvas_footer_button {
    border-top: 1px solid #1A1A1B;
    padding-top: 5px;
}
button.button_finalizare_comanda {
    background-color: #016131;
    border: none;
    width: 80%;
    margin: 10px 10% 15px 10%;
    height: 45px;
    border-radius: 4px;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 500;
}
.cantitate_order_checkout {
    display: flex;
    align-items: center;
}
.content_sidebar_order {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
}
.title_checkout_form_t{
    font-size: 18px;
    font-family: 'Montserrat';
}
.content_sub_check{
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.content_sub_check span{
    color: #1A1A1B;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 500;
}
.metoda_plata_order_check{
    margin-top: 12px;
    margin-bottom: 12px;
}
.metoda_plata_order_check label,
.metoda_plata_order_check p{
    color: #1A1A1B;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
}
.metoda_plata_order_check label a,
.metoda_plata_order_check p a{
    color: #016131;
    text-decoration: none;
}
.button_save_order_final{
    text-align: center;
}
.button_save_order_final button{
    background-color: #016131;
    border: none;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 5px 18px;
}
.peload_animation_create_big_ui{
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.peload_animation_create_big_product{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.peload_animation_create_big_ui_home{
    position: relative;
    width: 100%;
    height: 40vh;
    background-color: #fff;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_ecommerce_shop{
    color: #fff;
    background: #589dc3;
    border: 1px solid #589dc3;
    padding: 15px 25px 15px 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.info_ecommerce_shop p{
    margin-bottom: 0;
    margin-left: 8px;
}
.container_ecommerce_categ {
    padding-top: 25px;
    padding-bottom: 25px;
}
.container_ecommerce_categ h2{
    text-align: center;
    margin-bottom: 25px;
    color: #016131;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
}
.container_ecommerce_categ .card_category_data{
    background-color: #016131;
    margin-bottom: 15px;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
}
.container_ecommerce_categ .card_category_data h3{
    color: #fff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 500;
}
.container_ecommerce_categ .card_category_data a{
    background-color: #FFC400;
    color: #fff;
    text-decoration: none;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    padding: 5px 5px;
}
.carousel_section_prod{
    position: relative;
}
.carousel_section_prod .item_single_prod img,
.image_section_prod img{
    width: 100%;
    object-fit: contain;
}
.carousel_section_prod,
.image_section_prod {
    padding: 25px;
}
section.single_prod_ecommerce {
    padding-top: 25px;
    padding-bottom: 25px;
}
.tabs_elem_prod_desc_spe ul.nav-tabs li.nav-item button.active{
    background-color: #009152;
    color: #fff!important;
    border-color: #009152;
}
.tabs_elem_prod_desc_spe ul.nav-tabs li.nav-item button.nav-link{
    border-color: #009152;
    color: #009152;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
}
.tabs_elem_prod_desc_spe {
    padding-top: 20px;
}
.single_prod_ecommerce .product_data_single h1{
    color: #262626;
    font-family: 'Roboto';
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 18px;
}
.status_stock_single_out,
.status_stock_single {
    width: 90px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 12px;
}
.status_stock_single {
    background: #009152;
}
.status_stock_single_out{
    background: #ff2121;
}
.price_product_single{
    color: #016131;
    padding-top: 5px;
    padding-bottom: 5px;
}
.price_product_single p span.body_price bdi {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Roboto';
}
.data_single_prod_section{
    padding-bottom: 25px;
}
.buy_product_button button{
    background: #65bc51;
    color: #fff;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    border: none;
    padding: 12px 20px;
    display: flex;
    align-items: center;
}
.buy_product_button button:hover{
    background: #009152;
}
.single_prod_ecommerce .item_data_order{
    display: flex;
}
.single_prod_ecommerce .item_data_order svg {
    min-width: 25px;
    min-height: 25px;
    margin-right: 15px;
    fill: #016131;
}
.single_prod_ecommerce .item_data_order p{
    color:#878787;
    margin-bottom: 0;
}
.single_prod_ecommerce .item_data_order{
    border-top: 1px solid rgb(135 135 135 / 37%);
    margin-top: 20px;
    padding-top: 15px;
}
.delete_cart_product_style{
    background-color: #ea1919;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 25px;
    width: 25px;
    border-radius: 4px;
}
.order_data_prev_secret h4{
    color:#016131;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
}
.order_data_prev_secret ul{
    padding-left: 0;
}
.order_data_prev_secret ul li{
    list-style:none;
}
.order_data_prev_secret .container_bt_indiv_order{
    background-color: #39393814;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 15px;
    border-radius: 10px;
}
.button_search_product{
    float: right;
    margin-top: 10px;
    background-color: #016131;;
    color: #fff;
    border: none;
    padding: 6px 18px;
    font-size: 17px;
    font-weight: 500;
    border-radius: 4px;
}
@media (max-width: 1200.98px) {
    .slider_carousel_slick .carousel_prod_extern {
        padding: 20px 10px;
    }
    .card_produs_el_component .card_prod_image .cover_img_conmpnent {
        width: 100%;
        height: 280px;
    }
    .container_auth_section .row_interior_form {
        padding: 10px 5px;
    }
}
@media (max-width: 991.98px) {
    .elements_shop_mobile{
        display: block;
    }
    .elements_shop_desktop{
        display: none;
    }
    ul.commerce_menu_d_m {
        padding-left: 0px;
        padding-top: 5px;
    }
    .ecommerce_mobile_control{
        display: flex;
        align-items: center;
    }
    button.cart_flastone_ecommerce_shop svg {
        width: 22px;
        height: 22px;
    }
    button.cart_flastone_ecommerce_shop .badge_cart {
        font-size: 12px;
        width: 18px;
        height: 18px;
        top: -3px;
        right: -9px;
    }
    button.search_flastone_ecommerce svg {
        width: 22px;
        height: 22px;
        margin-right: 15px;
    }
    .ecommerce_mobile_control .navbar-toggler{
        background-color: #fff;
        padding: 4px 6px;
    }
    .ecommerce_mobile_control .navbar-toggler:focus {
        text-decoration: none;
        outline: 0!important;
        box-shadow: 0 0 0 0 var(--bs-navbar-toggler-focus-width);
    }
    .none_on_mobile_filter{
        display: none;
    }
    .sectin_filter_mobile{
        display: block;
        padding-bottom: 12px;
    }
    .single_prod_ecommerce .product_data_single h1 {
        font-size: 24px;
    }
}
@media (max-width: 767.98px) {
    .section_slide_home .slick-prev {
        left: 45%;
        top: 90%;
    }
    .section_slide_home .slick-next{
        right: 45%;
        top: 90%;
    }
    .section_slide_home .image_carousel {
        height: 80vh;
    }
    .card_produs_el_component .produse_info_details .produs_title_c {
        padding-bottom: 5px;
    }
    section.shops_carousels_home .title_categ_ecommerce_car {
        font-size: 25px;
    }
    .card_produs_el_component .card_prod_image .cover_img_conmpnent {
        width: 100%;
        height: 220px;
    }
    .data_shop_up_filter {
        justify-content: center;
        flex-wrap: wrap;
    }
    .data_shop_up_filter select.form-select,
    .data_shop_up_filter h3{
        width: 100%;
    }
    .data_shop_up_filter h3{
        text-align: center;
    }
    section.section_produse_categ {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .container_auth_section .row_interior_form {
        margin-bottom: 30px;
    }
    section.container_auth_section {
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .content_sidebar_order {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .product_data_single {
        margin-top: 35px;
    }
}
@media (max-width: 575.98px) {
    .section_slide_home .image_carousel {
        height: 85vh;
    }
    .section_slide_home .slick-prev {
        left: 35%;
        top: 90%;
    }
    .section_slide_home .slick-next{
        right: 35%;
        top: 90%;
    }
    .container_cover_slide h2 {
        font-size: 20px;
    }
    .container_cover_slide p {
        font-size: 15px;
    }
    .card_produs_el_component .produse_info_details .produs_title_c h4 {
        font-size: 20px;
    }
    .card_produs_el_component .produse_info_details .produs_title_c {
        padding-bottom: 5px;
    }
    section.shops_carousels_home .title_categ_ecommerce_car {
        margin-bottom: 0;
        font-size: 22px;
    }
    .card_produs_el_component .price_details_prod a.nchor_single_prod {
        width: 48px;
        height: 48px;
    }
    .sectin_filter_mobile {
        display: block;
        padding-bottom: 20px;
    }
    .filter_mobile_ignite {
        padding: 6px 18px;
    }
    section.bradcrumbs_page .brewd_crumb_container a {
        font-size: 16px;
    }
    section.bradcrumbs_page {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .shop_contact_form {
        margin-top: 45px;
        padding: 25px 15px;
    }
    section.container_auth_section {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .single_prod_ecommerce .product_data_single h1 {
        font-size: 20px;
    }
    .carousel_section_prod, .image_section_prod {
        padding: 10px;
    }
}
@media (max-width: 420.98px) {
    .ecommerce_navbar .navbar-brand {
        font-size: 18px;
    }
    button.search_flastone_ecommerce svg {
        margin-right: 10px;
    }
    .commerce_menu_d_m li a, .commerce_menu_d_m li button {
        font-size: 15px;
    }
}

@media (max-width: 380.98px) {
    button.cart_flastone_ecommerce_shop {
        margin-right: 10px;
    }
    .ecommerce_mobile_control .navbar-toggler {
        background-color: #fff;
        padding: 2px 3px;
    }
    .ecommerce_navbar .navbar-brand {
        font-size: 16px;
    }
    button.search_flastone_ecommerce svg {
        margin-right: 7px;
    }
}
@media (max-width: 338.98px) {
    button.search_flastone_ecommerce svg {
        margin-right: 4px;
    }
}